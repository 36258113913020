<!-- 通知详情 -->
<template>
  <div id="informdetails">
    <div>
      <!-- 头部地理位置和搜索框 -->
      <div class="head">
        <!-- 顶部NavBar 开始 -->
        <van-nav-bar
          class="usercenter"
          title="通知详情"
          left-text
          left-arrow
          @click-left="onClickLeft"
        />
      </div>
      <div class="cardstyle">
        <div class="title">{{ param.title }}</div>
        <div class="time">{{ param.updateDate }}</div>
        <div align="center" style="margin:10px 0;">
          <img :src="imagecard[0]" style="width: 80%;height: 100%;margin:0 auto;border-radius:10px">
        </div>
        <div id="pline" v-html="param.content">{{ param.content }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Project',
  data() {
    return {
      param: {},
      imagecard: [require('../../assets/images/notice01.png')]
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #EFF6FF')
  },
  created() {
    this.param.title = this.$route.params.title
    this.param.content = this.$route.params.content
    this.param.updateDate = this.$route.params.updateDate
    // console.log(this.param)
  },
  methods: {
    onClickLeft() {
      this.$router.back(-1)
    }
  }
}
</script>
<style>
.el-scrollbar__wrap {
   overflow-x: hidden;
}
.ql-align-center {
  text-align: center;
}
.ql-size-small {
    font-size: 0.75em;
}
.ql-size-large {
    font-size: 1.5em;
}
.ql-size-huge {
    font-size: 2.5em;
}
</style>
<style scoped lang="scss">
#informdetails {
  .usercenter {
    // background-color: rgba(255,255,255,0);
    background-image: url("../../assets/images/title_bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-bottom: 20px;
  }
  .cardstyle {
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px #dde9f3;
    margin-bottom: 15PX;
  }
  .title {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 5px;
    font-weight: 500;
  }
  .time {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #a1a1a1;
  }
  .content {
    line-height: 16px;
    margin-bottom: 5px;
    font-size: 14px;
    p {
      text-indent: 2em;
      line-height: 20px;
    }
  }
}
#informdetails .van-field__left-icon {
  color: white;
}

#informdetails .van-search__content--round {
  background-color: rgba(0, 0, 0, 0.2);
}

#informdetails .van-field__control {
  color: white;
}
#informdetails .van-nav-bar .van-icon {
  color: white;
}
#informdetails .van-nav-bar__title {
  color: white;
  font-size: 18px;
}
#informdetails .van-hairline--bottom::after {
  border-bottom-width: 0;
}
</style>
<style>
  #pline p{
    word-wrap:break-word;
  }
</style>
